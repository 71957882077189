<template>
  <div :class="{ invalid: !isValid }" class="custom-control custom-radio form-check">
    <input :name="name" :id="value" :checked="isChecked" :value="value" class="custom-control-input" type="radio"
           @change="handleChange">
    <label :for="value" class="custom-control-label">{{ label }}
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioComponent',
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
    isValid() {
      return !this.error;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
