<template>
  <ul class="actions top-right">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'ActionsComponent',
};
</script>

<style lang="scss" scoped>
.actions {
  &.top-right {
    top: 25px !important;
  }
  a {
    color: #667085;
    &.text-primary {
      color: var(--clr-yup-purple) !important;
    }
  }
  .material-symbols-outlined {
    vertical-align: middle;
  }
}
</style>
