<template>
  <div class="form-group" novalidate>
    <label v-if="label">{{ label }}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return [
        this.variant,
        { 'qt-loader qt-loader-mini qt-loader-right': this.loading },
      ];
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
