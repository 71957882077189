<template>
  <div :class="{ invalid: !isValid }" class="form-group">
    <label v-if="label">{{ label }}</label>
    <textarea :required="required" :value="value" class="form-control"
              @change="handleChange"
              @input="handleInput"></textarea>
    <div v-if="!isValid" class="form-text text-danger">
      <template v-for="error in errors">
        {{ error }}
      </template>
    </div>
  </div>
</template>

<script>
import validationRules from '@/rules';

export default {
  name: 'TextAreaComponent',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allRules: [],
      errors: [],
      fetched: false,
    };
  },
  computed: {
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);

      if (!this.isValid) {
        this.validate(event.target.value);
      }
    },
    handleChange(event) {
      this.validate(event.target.value);
      this.$emit('input', event.target.value);
    },
    checkValidation() {
      this.validate(this.value);
    },
    validate(value) {
      if (this.allRules.length) {
        this.errors = [];
        this.allRules.forEach((rule) => {
          rule = rule.split(':');
          if (validationRules[rule[0]]) {
            if (!validationRules[rule[0]].condition(value, rule[1])) {
              const message = validationRules[rule[0]].getMessage();

              if (message) {
                this.errors.push(this.$t(message, [rule[1]]));
              } else {
                this.errors.push('');
              }
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border-width: 2px;

.form-group {
  margin-bottom: 1.62rem;

  &.invalid {
    .form-control,
    .btn {
      border-color: #ff5c75;
    }
  }

  .form-control {
    padding: 12px 16px;
    border-width: $border-width;
    border-radius: 8px;
    border-color: #e5e5e5;

    &:disabled {
      border: 2px solid transparent;
    }
  }

  .form-text {
    font-size: 80%;
  }

  .btn {
    border-color: var(--form-border);
    border-width: $border-width;
    border-style: solid;
    border-radius: 8px;
    height: 45px;

    &.btn-icon {
      font-size: 24px;
    }
  }

  .input-group-prepend {
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .input-group-append {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}

label {
  margin-bottom: 0.62rem;
}
</style>
